import React, {useEffect, useState} from 'react';
import {
  ContentValue,
  Row,
  Section,
  TextBase,
  TextLabel,
  ViewBase,
} from '@/components/atoms';
import {useTranslation} from 'react-i18next';
import {CheckList, DrawerActions} from '@/store/modules';
import ListItemCheckList from '@/components/molecules/ListItemCheckList';
import BarProgress from '../BarProgress';
import {useTheme} from 'styled-components';
import {MdClose, MdSearch, MdAdd, MdDragIndicator} from 'react-icons/md';
import Input from '../Input';
import Button from '../Button';
import {Input as InputAnt} from 'antd';
import {ImCheckmark} from 'react-icons/im';
import Actions from './Actions';
import {getDateToday, isBetweenDate} from '@/utils/date';
import {ActivityService, Analytics, Toast} from '@/services';
import {useDispatch} from 'react-redux';
import MoveToChecklist from '@/components/organisms/MoveToChecklist';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

type Item = {
  item: any;
  index: number;
  value: boolean;
};
interface Props {
  data: CheckList[];
  handleCheckActivity(item: Item): void;
  handleDeleteItem?: any;
  handleAddNewItem?: any;
  handleUpdateItem?: any;
  label: string;
  activity: any;
  initialShowAdd?: boolean;
}
const ListCheckList = ({
  data,
  handleCheckActivity,
  label,
  handleAddNewItem,
  handleDeleteItem,
  handleUpdateItem,
  activity,
  initialShowAdd = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const theme = useTheme();
  const [showInput, setShowInput] = useState(false);
  const [showAdd, setShowAdd] = useState(initialShowAdd);
  const [search, setSearch] = useState('');
  const [text, setText] = useState('');
  const showSearch = data.length > 7;
  const [item, setItem] = useState<any>(null);
  const [moveToChecklist, setMoveToChecklist] = useState<any>(null);
  const [checklist, setChecklist] = useState(data);

  const [edit, setEdit] = useState<{
    index: null | number;
    text: string;
    done: boolean;
  }>({
    index: null,
    text: '',
    done: false,
  });
  const shouldEnableRescheduleFeature =
    activity &&
    activity?.checkListInDate &&
    !activity?.checkList?.find((i: any) => i.text === item?.text);

  const onUpdateItem = () => {
    handleUpdateItem(edit);
    setEdit({
      index: null,
      text: '',
      done: false,
    });
  };

  useEffect(() => {
    setChecklist(data);
  }, [data]);

  const onAddNewItem = () => {
    handleAddNewItem(text);
    setText('');
  };

  const handleRescheduleDate = (dateSchedule: string) => {
    if (!isBetweenDate(dateSchedule, activity)) {
      return Toast.show('Escolha uma data válida!');
    }
    ActivityService.rescheduleItemCheckList(dateSchedule, item, activity, data);
  };

  const onTransformInTask = () => {
    dispatch(
      DrawerActions.setDrawer({
        action: 'ADD_TASK',
        payload: {activity: {title: item?.text}},
      }),
    );
    Analytics.log('transform_item_checklist_to_task');
  };

  const onMoveToChecklist = () => {
    setMoveToChecklist({
      task: activity,
      item,
      itemDate: activity?.date || getDateToday(),
    });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setChecklist(items);
    ActivityService.updateCheckList(activity, items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {moveToChecklist && (
        <MoveToChecklist
          {...(moveToChecklist || {})}
          visible={!!moveToChecklist}
          close={() => setMoveToChecklist(null)}
        />
      )}
      {data?.length > 0 && (
        <Section>
          <TextLabel>{t('progress')}</TextLabel>
          <ContentValue>
            <BarProgress
              score={data.filter(i => i.done)?.length}
              level={{scoreDown: 0, scoreTop: data?.length}}
              showRate
            />
          </ContentValue>
        </Section>
      )}
      <Section>
        <Row spaceBetween>
          <TextLabel>{label || t('checkList')}</TextLabel>
          <Row>
            {(showSearch && showInput) || showAdd ? (
              <MdClose
                color={theme.colors.onBackground}
                size={23}
                onClick={() => {
                  setShowAdd(false);
                  setShowInput(false);
                  setSearch('');
                }}
              />
            ) : showSearch ? (
              <MdSearch
                color={theme.colors.onBackground}
                size={23}
                onClick={() => {
                  setShowInput(true);
                }}
              />
            ) : null}
            {handleAddNewItem && (
              <MdAdd
                size={23}
                color={theme.colors.onBackground}
                onClick={() => setShowAdd(true)}
                style={{marginLeft: 5}}
              />
            )}
          </Row>
        </Row>
        {showSearch && showInput && (
          <Input
            value={search}
            onChangeText={setSearch}
            placeholder="Search by text ..."
            style={{
              marginBottom: theme.metrics.space.small,
              borderRadius: theme.metrics.space.smaller,
              backgroundColor: theme.colors.surface,
            }}
          />
        )}
        {showAdd && (
          <Row>
            <Input
              value={text}
              onChangeText={setText}
              placeholder="Add item ..."
              style={{
                marginBottom: theme.metrics.space.small,
                borderRadius: theme.metrics.space.smaller,
                backgroundColor: theme.colors.surface,
              }}
              onSubmitEditing={onAddNewItem}
            />
            <Button
              title={t('add')}
              onPress={onAddNewItem}
              borderRadius={4}
              width={150}
              fontSize={15}
              height={32}
              marginLeft={4}
            />
          </Row>
        )}
        <Droppable droppableId="tasks">
          {provided => (
            <ContentValue
              style={{
                flexDirection: 'column',
                padding: '4px 8px',
                // ...provided.droppableProps?.style,
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {data.length === 0 && (
                <TextBase>{t('noActivitiesAdded')}</TextBase>
              )}
              {checklist
                ?.filter(i =>
                  String(i?.text).toUpperCase()?.includes(search.toUpperCase()),
                )
                ?.map((item, index) => (
                  <Draggable
                    key={String(index) + item.text}
                    draggableId={String(index) + item.text}
                    index={index}>
                    {provided => (
                      <Row
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={String(index) + item.text}
                        alignCenter>
                        <ViewBase center {...provided.dragHandleProps}>
                          <MdDragIndicator
                            size={20}
                            color={theme.colors.text}
                          />
                        </ViewBase>
                        <ViewBase
                          style={{
                            width: '98%',
                          }}>
                          <ListItemCheckList
                            key={String(index) + item.text}
                            item={item}
                            keyLabel="text"
                            value={item.done}
                            onCheckItem={() =>
                              handleCheckActivity({
                                item,
                                index,
                                value: !item.done,
                              })
                            }
                            renderValue={
                              edit.index === index
                                ? v => (
                                    <InputAnt
                                      value={edit.text}
                                      onChange={e =>
                                        setEdit(v => ({
                                          ...v,
                                          text: e.target.value,
                                        }))
                                      }
                                      className={
                                        theme.title === 'dark'
                                          ? 'input-dark'
                                          : 'input-light'
                                      }
                                      onPressEnter={onUpdateItem}
                                    />
                                  )
                                : undefined
                            }
                          />
                        </ViewBase>

                        {handleUpdateItem && edit.index === index && (
                          <ImCheckmark
                            size={20}
                            color={theme.colors.text}
                            onClick={onUpdateItem}
                          />
                        )}
                        <Actions
                          defaultDate={activity.date || getDateToday()}
                          onDeleteItem={() => handleDeleteItem(item as any)}
                          onUpdateItem={() => setEdit({...item, index})}
                          onRescheduleItem={
                            shouldEnableRescheduleFeature
                              ? (date: string) => handleRescheduleDate(date)
                              : undefined
                          }
                          onClickItem={() => setItem(item)}
                          onTransformInTask={onTransformInTask}
                          onMoveToChecklist={onMoveToChecklist}
                        />
                      </Row>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ContentValue>
          )}
        </Droppable>
      </Section>
    </DragDropContext>
  );
};

export default ListCheckList;
