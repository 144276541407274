import {format, parseISO} from 'date-fns';
import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, List} from 'antd';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {translatePeriod} from '@/utils/translate';
import {useTheme} from 'styled-components';
import {getDateToday, getTimeStringFromSecunds} from '@/utils/date';
import {INFINITY_DATE, JUST_TODAY, Period} from '@/constants';
import {Column, TextValue, TextLabel, Row} from '../atoms';
import Notes from '../molecules/Notes';
import HeaderDetails from '@/forms/HeaderDetails';
import DetailsDay from './DetailsDay';
import Statistics from './Statistics';
import AddNote from '../molecules/AddNote';
import {DrawerActions, GoalActions, TaskActions} from '@/store/modules';
import {t} from '@/services/Locale';
import Input from '../Input';
import DailyGoal from '../molecules/DailyGoal';
import {ActivityService} from '@/services';
import ListCheckList from '../molecules/ListCheckList';
import {selectorActivity} from '@/store/modules/utils';
import {validator} from '@/utils/validator';
import CheckLists from './CheckLists';

const field = {
  task: [
    'title',
    'label',
    'description',
    'categories',
    'startHour',
    'endHour',
    'startDate',
    'endDate',
    'checkList',
    'period',
    'notifications',
  ],
  goal: [
    'specific',
    'targetAmount',
    'date',
    'startDate',
    'relevant',
    'progress',
    'categories',
    'endHour',
    'achievable',
  ],
  habit: [
    'trigger',
    'routine',
    'reward',
    'categories',
    'startHour',
    'endHour',
    'startDate',
    'endDate',
    'period',
    'notifications',
  ],
};
const formatValue = (key, value, categories, habits) => {
  switch (key) {
    case 'startDate':
    case 'date':
    case 'createdAt':
    case 'endDate':
      return value === INFINITY_DATE
        ? i18next.t('never')
        : format(parseISO(value), 'dd/MM/yyyy');
    case 'categories':
      return categories
        .filter(c => value.includes(c.uid))
        .map(i => i18next.t(i.name))
        .join(', ');
    case 'period':
      return translatePeriod(value);
    case 'notifications':
      return value ? value.map(i => i.hour).toString() : 'off';
    // case "achievable":
    // case "checkList":
    // return  (
    //   <>
    //     {value.map((i, index) => (
    //       <span key={i.text}>
    //         {`${index + 1}. (${i.done ? "x" : " "}) ${i.text}`} <br></br>{" "}
    //       </span>
    //     ))}
    //   </>
    // );
    case 'progress':
      if (value.includes('habit')) {
        const [, id] = value.split('_');
        const habitId = parseInt(id) ? parseInt(id) : id;
        const habit = habits.find(
          i => id && (habitId === i.id || habitId === i.uid),
        );
        if (habit) return t('habit') + ': ' + habit?.routine;
        return t('habit');
      }
      return t(value);

    default:
      return value;
  }
};

function formatViewDetails(activity, type, categories, habits) {
  return Object.entries(activity)
    .filter(i => !!i[1])
    .filter(i => field[type].includes(i[0]))
    .map(i => ({
      label: i18next.t(i[0].replace('targetAmount', 'amount')),
      value: formatValue(i[0], i[1], categories, habits),
    }));
}

const Details = ({activity: _activity, type, activityDate: _activityDate}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [dailyGoal, setDailyGoal] = useState(false);
  const [indexTab, setIndexTab] = useState(type === 'goal' ? 2 : 0);
  const [visible, setVisible] = useState(false);
  const activityModel = useSelector(stt => selectorActivity(stt, _activity));
  const activity = useMemo(
    () => ({..._activity, ...activityModel}),
    [_activity, activityModel],
  );

  const activityDate = useMemo(() => {
    if ('specific' in activity)
      return (
        activity?.dates?.find(i => i.date === getDateToday()) || {
          note: null,
          date: getDateToday(),
          amount: 0,
        }
      );
    if ('title' in activity)
      return (
        activity?.dones.find(i => i.date === activity.date) || {
          note: null,
          date: activity.date,
          deleted: false,
          done: false,
        }
      );
    if ('routine' in activity)
      return (
        activity?.dones.find(i => i.date === activity.date) || {
          note: null,
          date: activity.date,
          deleted: false,
          done: false,
          amount: 0,
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const categories = useSelector(state => state.category.categories);
  const habits = useSelector(state => state.habit.habits);
  const dates = activity?.dones || activity?.dates || [];
  const listDetails = useMemo(
    () => formatViewDetails(activity, type, categories, habits),
    [activity, type, categories, habits],
  );
  const isGoal = 'specific' in activity;
  const isTask = 'title' in activity;

  function handleAddAmount(e) {
    setDailyGoal(true);
  }
  function onCheckAmount(amount) {
    setDailyGoal(false);
    const nextAmount = amount + (activityDate?.amount || 0);
    ActivityService.updateInDate(activity, getDateToday(), {
      amount: nextAmount,
    });
  }
  const handleDeleteItem = item => {
    if (isGoal)
      dispatch(
        GoalActions.updateGoalRequest({
          ...activity,
          achievable: activity.achievable.filter(i => i.text !== item.text),
        }),
      );
    if (isTask)
      dispatch(
        TaskActions.updateTaskRequest({
          ...activity,
          checkList: activity.checkList.filter(i => i.text !== item.text),
        }),
      );
  };
  const handleUpdateItem = item => {
    if (isGoal)
      dispatch(
        GoalActions.updateGoalRequest({
          ...activity,
          achievable: activity.achievable.map((i, index) =>
            index === item.index ? {text: item.text, done: item.done} : i,
          ),
        }),
      );
    if (isTask)
      dispatch(
        TaskActions.updateTaskRequest({
          ...activity,
          checkList: activity.checkList.map((i, index) =>
            index === item.index ? {text: item.text, done: item.done} : i,
          ),
        }),
      );
  };
  const onAddNewItem = text => {
    const isValid = validator([text], t('error:checkListRequired'));
    if (!isValid) {
      return;
    }
    const checkList = activity.achievable || activity.checkList || []
    const list = [
      ...(checkList),
      {
        done: false,
        text: ActivityService.formatTextToChecklist(text, checkList),
      },
    ];
    ActivityService.updateCheckList(activity, list);
  };

  function handleCheckActivity({item}) {
    if (isGoal) {
      dispatch(
        GoalActions.updateGoalRequest({
          ...activity,
          achievable: activity.achievable.map(i =>
            i.text === item.text
              ? {...i, done: !i.done, doneAt: !i.done ? getDateToday() : null}
              : i,
          ),
        }),
      );
    }
    if (isTask) {
      dispatch(
        TaskActions.updateTaskRequest({
          ...activity,
          checkList: activity.checkList.map(i =>
            i.text === item.text ? {...i, done: !i.done} : i,
          ),
        }),
      );
    }
  }

  return (
    <>
      {visible && (
        <AddNote
          setVisible={setVisible}
          visible={visible}
          initialDate={activity.date}
          activity={activity}
        />
      )}
      <HeaderDetails
        indexTab={indexTab}
        setIndexTab={setIndexTab}
        type={type}
        tabs={[
          activity.startDate && activity.period !== JUST_TODAY
            ? {
                title: t('detailsDay'),
                Component: (
                  <DetailsDay
                    activity={activity}
                    type={type}
                    activityDate={activityDate}
                  />
                ),
              }
            : null,
          activity.startDate && activity.period !== JUST_TODAY
            ? {
                title: t('statistic:title'),
                Component: <Statistics activity={activity} type={type} />,
              }
            : null,
          {
            title: t('details'),
            Component: (
              <>
                <List
                  dataSource={listDetails}
                  renderItem={item =>
                    [t('achievable'), t('checkList')].includes(item.label) ? (
                      <ListCheckList
                        data={item.value}
                        label={item.label}
                        handleCheckActivity={handleCheckActivity}
                        handleDeleteItem={handleDeleteItem}
                        handleUpdateItem={handleUpdateItem}
                        handleAddNewItem={onAddNewItem}
                        initialShowAdd
                        activity={activity}
                      />
                    ) : (
                      <Column
                        key={item.label}
                        style={{
                          margin: 5,
                          paddingBottom: 5,
                          borderBottom: '1px solid ' + theme.colors.surface,
                        }}>
                        <TextLabel>{item.label}</TextLabel>
                        <TextValue>{item.value}</TextValue>
                      </Column>
                    )
                  }
                  style={{borderColor: theme.colors.surface}}
                />

                {'specific' in activity && (
                  <Column
                    style={{
                      margin: 5,
                      paddingBottom: 5,
                      borderBottom: '1px solid ' + theme.colors.surface,
                    }}>
                    <TextLabel>{t('time')}</TextLabel>
                    <Row spaceBetween>
                      <TextValue>
                        {getTimeStringFromSecunds(
                          dates.reduce(
                            (acc, item) =>
                              acc + (item.timer || 0) + (item.stopwatch || 0),
                            0,
                          ),
                        )}
                      </TextValue>
                      <Button
                        style={{
                          backgroundColor: theme.colors.surface,
                          color: theme.colors.onBackground,
                          borderColor: theme.colors.regular,
                        }}
                        onClick={() => {
                          dispatch(
                            DrawerActions.setDrawer({
                              action: `TIMER`,
                              payload: {activity, tab: 1},
                            }),
                          );
                        }}>
                        {t('toStart')}
                      </Button>
                    </Row>
                  </Column>
                )}

                {dailyGoal && (
                  <DailyGoal
                    title={t('addProgress')}
                    onCheckAmount={onCheckAmount}
                    amount={activity?.targetAmount}
                    amountTotal={activity?.targetAmount}
                    setVisible={setDailyGoal}
                    visible={dailyGoal}
                    activity={activity}
                  />
                )}

                {activity.progress === 'amount' && (
                  <Column
                    style={{
                      margin: 5,
                      paddingBottom: 5,
                      borderBottom: '1px solid ' + theme.colors.surface,
                    }}>
                    <TextLabel>{t('addProgress')}</TextLabel>
                    <Row>
                      <Input
                        className={
                          theme.title === 'dark' ? 'input-dark' : 'input-light'
                        }
                        placeholder={t('placeholderAmount')}
                        value={activityDate?.amount}
                        disabled
                      />
                      <Button
                        style={{
                          backgroundColor: theme.colors.surface,
                          color: theme.colors.onBackground,
                          borderColor: theme.colors.regular,
                        }}
                        onClick={handleAddAmount}>
                        {t('add')}
                      </Button>
                    </Row>
                  </Column>
                )}
              </>
            ),
          },
          activity.period !== Period.JUST_TODAY
            ? {
                id: 'checklists',
                title: `${t('checkList')}s`,
                Component: (
                  <CheckLists
                    dates={dates?.filter(i => i?.checkList) || []}
                    activity={activity}
                    initialDate={getDateToday()}
                  />
                ),
              }
            : false,
          {
            title: t('notes'),
            Component: (
              <Notes
                dates={dates?.filter(i => i.note) || []}
                title={t('notes')}
                renderItem={i => i.note}
                titleAddNew={t('addNote')}
                onAddNew={() => setVisible(true)}
              />
            ),
          },
          {
            title: t('time'),
            Component: (
              <Notes
                dates={dates?.filter(i => i.timer || i.stopwatch) || []}
                title={t('time')}
                renderItem={i =>
                  getTimeStringFromSecunds(
                    (i?.timer || 0) + (i?.stopwatch || 0),
                  )
                }
                titleAddNew={t('toStart')}
                onAddNew={() =>
                  dispatch(
                    DrawerActions.setDrawer({
                      action: `TIMER`,
                      payload: {activity, tab: 1},
                    }),
                  )
                }
              />
            ),
          },
        ].filter(Boolean)}
      />
    </>
  );
};

export default Details;
